@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  background-color: #FCEEDF;
}

/* 
@media only screen and (max-width: 600px) {
  html {
    font-size: 10px;
  }
}

@media only screen and (min-width: 600px) {
  html {
    font-size: 13px;
  }
}

@media only screen and (min-width: 992px) {
  html {
    font-size: 14px;
  }
}

@media only screen and (min-width: 1200px) {
  html {
    font-size: 17px;
  }
} */